import Vue from 'vue'
import { Doughnut } from 'vue-chartjs'
import ChartDataLabels from 'chartjs-plugin-datalabels';
Vue.use(ChartDataLabels)

export default {
  name: 'pizza-doughnut-chart',
  extends: Doughnut,
  props: {
    labels: {
      type: [Object, Array],
      description: 'Chart labels. This is overridden when `data` is provided'
    },
    data: {
      type: [Object, Array],
      description: 'Chart.js chart data (overrides all default data)'
    },
    datasets: {
      type: [Object, Array],
      description: 'Chart datasets. This is overridden when `data` is provided'
    },
    extraOptions: {
      type: Object,
      description: 'Chart.js options'
    },
  },
  data() {
    return {
      defaultoptions: {
        cutoutPercentage: 70,
        legend: {
          position: "bottom",
        },
        plugins: {
          datalabels: {
            formatter: (value, ctx) => {
              let sum = this.data.reduce((a, b) => a + b, 0);
              let porcentage = (value * 100 / sum).toFixed(1) + "%";
              return porcentage
            },
            display: "auto",
            borderColor: "black",
            borderRadius: 25,
            color: '#66615c',
            anchor: "center",
            align: "center",
            font: {
              weight: "bold",
              size: 25,
            },
          },
        },
        responsive: true,
        aspectRatio: false,
      }
    }
  },
  methods:{
    assignChartData() {
      return {
        labels: this.labels,
        datasets: this.datasets ? this.datasets : [{
          labels: this.labels,
          borderWidth: 1,
          backgroundColor: [
            '#a2d1e9',
            '#7daee0',
            '#458bb8',
            '#52cf9a',
            '#01e592',
            '#03bc7b',
            '#c7f135',
            '#faef1a',
            '#d4950c',
          ],
          data: this.data || []
        }]
      }
    },
    assignChartOptions(initialConfig) {
      let extraOptions = this.extraOptions || {};
      return {
        ...initialConfig,
        ...extraOptions
      }

    }
  },
  mounted() {
    this.addPlugin(ChartDataLabels);
    this.chartData = this.assignChartData({});
    this.options = this.assignChartOptions(this.defaultoptions);
    this.renderChart(this.chartData, this.options)
  }
}
