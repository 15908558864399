import { render, staticRenderFns } from "./OverviewBroker.vue?vue&type=template&id=40a060dd&scoped=true&"
import script from "./OverviewBroker.vue?vue&type=script&lang=js&"
export * from "./OverviewBroker.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "40a060dd",
  null
  
)

export default component.exports