<template>
  <module
      ref="module"
      id="overviewBroker"
      titleIcon="nc-icon nc-layout-11"
      :title="$t('dashboard')"
      :use-default-list="false"
    >
    <div slot="global">
      <div v-if="loading" class="text-center">
        <h2>{{ $t('loadingtxt') }} {{ $t('dashboard') }}. {{ $t('pleasewait') }}</h2>
        <i class="fa fa-cog fa-spin fa-huge fa-fw" style="color: #51bcda;"></i>
      </div>
      <div v-if="!loading" class="row">

        <div class="col-lg-3 col-sm-6">
          <circle-chart-card
            :percentage="getConnectedPercentage()"
            :title="`${$t('connected')}`"
            :description="$t('outOfTotalAccounts')"
            color="#51bcda">
            <template slot="footer">
              <div class="legend">
                <i class="fa fa-circle text-info"></i>
                {{ $t('connected') }}: {{ connected }} {{ $t('outoftxt') }} {{ accounts.length }}
              </div>
            </template>
          </circle-chart-card>
        </div>

        <div class="col-lg-3 col-sm-6">
          <doughnut-chart
            :data="this.connected_accounts_data"
            :labels="this.accounts_label_extense"
            :height=250
            :title="$t('connectedAccountsPerCountry')"
            :description="$t('outOfConnectedAccounts')">
            <template slot="footer">
              <div class="legend">
                &nbsp
              </div>
            </template>
          </doughnut-chart>
        </div>


        <div class="col-lg-3 col-sm-6">
          <doughnut-chart
            :data="this.accounts_per_strat_data"
            :labels="this.accounts_per_strat_label"
            :height=250
            :title="$t('connectedAccountsPerStrategy')"
            :description="$t('outOfConnectedAccounts')"
          >
            <template slot="footer">
              <div class="legend">
                &nbsp
              </div>
            </template>
          </doughnut-chart>
        </div>


        <div class="col-lg-3 col-sm-6">
          <stats-card
            type="info"
            icon="nc-icon nc-money-coins"
            :small-title="`${$t('balance')}`"
            :title="balance | currency({symbol : '$', thousandsSeparator: '.', fractionCount: 2, fractionSeparator: ',', symbolPosition: 'front', symbolSpacing: true})">
            <div class="stats" slot="footer">
              <i class="nc-icon nc-alert-circle-i"></i>
              {{ $t('totaltxt') }} {{ $t('balance') }}
            </div>
          </stats-card>

          <stats-card
            type="info"
            icon="nc-icon nc-money-coins"
            :small-title="`${$t('equity')}`"
            :title="equity | currency({symbol : '$', thousandsSeparator: '.', fractionCount: 2, fractionSeparator: ',', symbolPosition: 'front', symbolSpacing: true})">
            <div class="stats" slot="footer">
              <i class="nc-icon nc-alert-circle-i"></i>
              {{ $t('totaltxt') }} {{ $t('equity') }}
            </div>
          </stats-card>

        </div>

      </div>
    </div>
  </module>
</template>

<script>
import Vue from 'vue'
import CircleChartCard from "@/components/UIComponents/Cards/CircleChartCard";
import DoughnutChart from "@/components/UIComponents/Charts/ChartDoughnut";
import ChartDoughnut from "@/components/UIComponents/Charts/ChartDoughnut";
import StatsCard from "@/components/UIComponents/Cards/StatsCard";
import VueCurrencyFilter from 'vue-currency-filter';

Vue.use(VueCurrencyFilter)

const user_data = JSON.parse(localStorage.getItem('user-info'));

export default {
  name: "OverviewBroker",
  components: {
    CircleChartCard,
    DoughnutChart,
    ChartDoughnut,
    StatsCard
  },
  data() {
    return {
      loading: true,
      broker: user_data.app_metadata.broker,
      accounts: [],
      connected: 0,
      unassigned: 0,
      equity: 0,
      balance: 0,
      countryDataLabel: [],
      countryData: [],
      connected_accounts_label: [],
      connected_accounts_data: [],
      accounts_per_strat_label: [],
      accounts_per_strat_data: [],
      countries: this.$country_list(),
      accounts_label_extense: []
    }
  },
  methods: {
    loadCharts() {
      this.$listBrokerChart({
        broker: this.broker,
      }).then(this.successHandler, this.errorHandler)
    },
    successHandler(resp) {
      this.loading = false;
      if (!resp.success) {
        return;
      }
      this.accounts = resp.data.accounts;
      for (let row of resp.data.accounts) {
        this.balance = this.balance + row.balance
        this.equity = this.equity + row.equity
        if (row.strat_name) {
          this.connected += 1;
        } else {
          this.unassigned += 1;
        }
        if (row.country == 'IT') {
          this.countBr += 1;
        }
      }

      for (let row of resp.data.connected_accounts_per_country) {
        if (row.country) {
          this.connected_accounts_label.push(row.country)
          this.connected_accounts_data.push(row.count)
        }
      }
      this.changeCountryLabel()

      for (let row of resp.data.accounts_per_strategy) {
        if (row.strat) {
          this.accounts_per_strat_label.push(row.strat)
          this.accounts_per_strat_data.push(row.count)
        }
      }

    },
    changeCountryLabel() {
      Object.values(this.connected_accounts_label).forEach(label => {
        Object.values(this.countries).forEach(value => {
          if (value.code == label) {
            this.accounts_label_extense.push(value.name)
            return
          }
        })
      })
    },
    errorHandler() {
      this.loading = false;
    },
    getConnectedPercentage() {
      if (this.accounts.length == 0) {
        return 0;
      }
      return parseFloat((this.connected * 100) / this.accounts.length).toFixed(2)
    },
    getUnassignedPercentagem() {
      if (this.accounts.length == 0) {
        return 0;
      }
      return parseFloat((this.unassigned * 100) / this.accounts.length).toFixed(2);
    },
    getCountryPercentagem() {
      if (this.accounts.length == 0) {
        return 0;
      }
      return parseFloat((this.unassigned * 100) / this.accounts.length).toFixed(2);
    }
  },
  mounted() {
    this.loadCharts()
  },
  created(){
    this.$setTranslatingInProgress()
  },
  beforeDestroy(){
    this.$root.$off("showTranslatingProgress")
  }
}
</script>

<style scoped>

</style>
