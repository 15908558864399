<template>
    <div class="card">
    <div class="card-header text-center">
      <slot name="header">
        <h5 class="card-title">{{title}}</h5>
        <p class="card-category">{{description}}</p>
      </slot>
    </div>
    <div class="card-body">
      <pizza-doughnut-chart
                      :data="data"
                      :labels="labels"
                      :height="250">
      </pizza-doughnut-chart>
    </div>
    <div class="card-footer">
      <slot name="footer"></slot>
    </div>
  </div>
</template>
<script>
import PizzaDoughnutChart from "@/components/UIComponents/Charts/PizzaDoughnutChart";

export default {
  name: 'chart-doughtnut',
  components: {
    PizzaDoughnutChart
  },
  props: {
    labels: {
      type: [Object, Array],
      description: 'Chart labels. This is overridden when `data` is provided'
    },
    data: {
      type: [Object, Array],
      description: 'Chart.js chart data (overrides all default data)'
    },
    title: {
        type: String,
        description: 'Chart card title'
      },
    description: {
        type: String,
        description: 'Chart card description'
    },
  },
  // data() {
  //   return {
  //     chartData: {
  //       labels: this.labels,
  //       datasets: [{
  //         borderWidth: 1,
  //         backgroundColor: [
  //           '#a2d1e9',
  //           '#7daee0',
  //           '#458bb8',
  //           '#52cf9a',
  //           '#01e592',
  //           '#03bc7b',
  //           '#c7f135',
  //           '#faef1a',
  //           '#d4950c',
  //         ],
  //         data: this.data
  //       }]
  //     },
  //     options: {
  //       cutoutPercentage: 70,
  //       legend: {
	// 					position: "bottom",
	// 				},
  //       plugins: {
  //         datalabels: {
  //           formatter: (value, ctx) => {
  //             let sum = this.data.reduce((a, b) => a + b , 0);
  //             let porcentage = (value * 100 / sum).toFixed(1)+"%";
  //             return porcentage
  //           },
  //           display: "auto",
  //           borderColor: "black",
  //           borderRadius: 25,
  //           color: '#66615c',
  //           anchor: "center",
  //           align: "center",
  //           font: {
  //             weight: "bold",
  //             size: 25,
  //           },
  //         },
  //       },
  //
  //       responsive: true,
  //       aspectRatio: false,
  //     },
  //
  //   }
  // }
}
</script>

<style scoped>

</style>
